import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: 'Últimos 5 minutos' },
  { from: 'now-15m', to: 'now', display: 'Últimos 15 minutos' },
  { from: 'now-30m', to: 'now', display: 'Últimos 30 minutos' },
  { from: 'now-1h', to: 'now', display: 'Última 1 hora' },
  { from: 'now-3h', to: 'now', display: 'Últimas 3 horas' },
  { from: 'now-6h', to: 'now', display: 'Últimas 6 horas' },
  { from: 'now-12h', to: 'now', display: 'Últimas 12 horas' },
  { from: 'now-24h', to: 'now', display: 'Últimas 24 horas' },
  { from: 'now-2d', to: 'now', display: 'Últimos 2 dias' },
  { from: 'now-7d', to: 'now', display: 'Últimos 7 dias' },
  { from: 'now-30d', to: 'now', display: 'Últimos 30 dias' },
  { from: 'now-90d', to: 'now', display: 'Últimos 90 dias' },
  { from: 'now-6M', to: 'now', display: 'Últimos 6 meses' },
  { from: 'now-1y', to: 'now', display: 'Último 1 ano' },
  { from: 'now-2y', to: 'now', display: 'Últimos 2 anos' },
  { from: 'now-5y', to: 'now', display: 'Últimos 5 anos' },
  { from: 'now-1d/d', to: 'now-1d/d', display: 'Ontem' },
  { from: 'now-2d/d', to: 'now-2d/d', display: 'Anteontem' },
  { from: 'now-7d/d', to: 'now-7d/d', display: 'Este dia na semana passada' },
  { from: 'now-1w/w', to: 'now-1w/w', display: 'Semana passada' },
  { from: 'now-1M/M', to: 'now-1M/M', display: 'Mês anterior' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: 'Trimestre fiscal anterior' },
  { from: 'now-1y/y', to: 'now-1y/y', display: 'Ano anterior' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: 'Ano fiscal anterior' },
  { from: 'now/d', to: 'now/d', display: 'Hoje' },
  { from: 'now/d', to: 'now', display: 'hoje até agora' },
  { from: 'now/w', to: 'now/w', display: 'Esta semana' },
  { from: 'now/w', to: 'now', display: 'Esta semana até agora' },
  { from: 'now/M', to: 'now/M', display: 'Este mês' },
  { from: 'now/M', to: 'now', display: 'Este mês até agora' },
  { from: 'now/y', to: 'now/y', display: 'Este ano' },
  { from: 'now/y', to: 'now', display: 'Este ano até agora' },
  { from: 'now/fQ', to: 'now', display: 'Este trimestre fiscal até agora' },
  { from: 'now/fQ', to: 'now/fQ', display: 'Este trimestre fiscal' },
  { from: 'now/fy', to: 'now', display: 'Este ano fiscal até agora' },
  { from: 'now/fy', to: 'now/fy', display: 'Este ano fiscal' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];
